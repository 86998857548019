import styled from "styled-components"
import {
  Container as BaseContainer,
  Grid as BaseGrid,
} from "../../elements/Layout/styled"

export const Container = styled(BaseContainer)``

export const Grid = styled(BaseGrid)`
  grid-template-columns: 1fr;
`
