import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

import { handleSignin, isSignedIn } from "../utils/auth"
import { API_URL } from "../utils/helpers"

import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"

import Nav from "../components/Nav/index"

import Input from "../components/form/Input"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"

import { Container, Grid } from "../components/charity-add/styled"

const SignIn = () => {
  const [names, setNames] = useState({})
  const [errors, setErrors] = useState([])
  const [msg, setMsg] = useState({})

  useEffect(() => {
    if (isSignedIn()) {
      navigate("/")
    }
  }, [])

  const handleUpdate = (name, value) => {
    setNames((names) => ({ ...names, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/?f=adminSignin`)
    let formData = new FormData()
    Object.entries(names).forEach(([key, value]) => {
      formData.append(key, value)
    })

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        if (handleSignin(json.token)) {
          navigate("/")
        } else {
          setMsg({
            type: "error",
            text: "An error occurred signing in.",
          })
        }
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet />
      <Container>
        <Nav sections={[{ name: "Admin Sign In", to: "" }]} title="" />

        <p className="no-top-margin">
          Enter your username & password to access the BFC administration area.
        </p>

        <Grid>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="two">
              <div>
                <Input
                  type="text"
                  label="Username"
                  req={true}
                  name="uname"
                  value={names.uname}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
              <div>
                <Input
                  type="password"
                  label="Password"
                  req={true}
                  name="pwd"
                  value={names.pwd}
                  update={handleUpdate}
                  errors={errors}
                />
              </div>
            </div>

            {msg.type && <Msg data={msg} />}

            {msg.type !== "working" && <Submit label="Sign In" />}
          </form>
        </Grid>
      </Container>
    </Layout>
  )
}

export default SignIn
